// extracted by mini-css-extract-plugin
export var column = "ProviderShowLocation__column__EA5q4";
export var relatedLocationChevron = "ProviderShowLocation__relatedLocationChevron__G7kjN";
export var relatedLocationContainer = "ProviderShowLocation__relatedLocationContainer__v9XZJ";
export var relatedLocationImage = "ProviderShowLocation__relatedLocationImage__VNb1F";
export var relatedLocationLeft = "ProviderShowLocation__relatedLocationLeft__b3pxE";
export var relatedLocationLink = "ProviderShowLocation__relatedLocationLink__J8x_2";
export var relatedLocationRight = "ProviderShowLocation__relatedLocationRight__aZrMs";
export var relatedLocationSummary = "ProviderShowLocation__relatedLocationSummary__U90VE";
export var relatedLocationSummaryAddress = "ProviderShowLocation__relatedLocationSummaryAddress__jP13l";
export var relatedLocationSummaryProvider = "ProviderShowLocation__relatedLocationSummaryProvider__L_W3V";
export var row = "ProviderShowLocation__row__NyvEg";