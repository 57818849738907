// extracted by mini-css-extract-plugin
export var bottomContainer = "ProviderShow__bottomContainer__TQAV6";
export var breadcrumbLink = "ProviderShow__breadcrumbLink__NAQ6e";
export var breadcrumbs = "ProviderShow__breadcrumbs__IWH_0";
export var column = "ProviderShow__column__sYTWe";
export var container = "ProviderShow__container__lCUBR";
export var leftContainer = "ProviderShow__leftContainer__FhI0J";
export var mainContent = "ProviderShow__mainContent__oQraF";
export var providerDetails = "ProviderShow__providerDetails__xPLar";
export var rightContainer = "ProviderShow__rightContainer__s8Tya";
export var row = "ProviderShow__row__eaOk4";
export var sidebar = "ProviderShow__sidebar__d28hW";
export var topContainer = "ProviderShow__topContainer__JSsUt";