// extracted by mini-css-extract-plugin
export var breadcrumbs = "ProviderShowMainContent__breadcrumbs__rTpbW";
export var column = "ProviderShowMainContent__column__tb8gr";
export var contentDetails = "ProviderShowMainContent__contentDetails__UGOAN";
export var contentDetailsTitle = "ProviderShowMainContent__contentDetailsTitle__EYA1d";
export var contentFacilities = "ProviderShowMainContent__contentFacilities__Ruixa";
export var contentFeatures = "ProviderShowMainContent__contentFeatures__kMwZs";
export var contentSummary = "ProviderShowMainContent__contentSummary__OmQHM";
export var contentTabs = "ProviderShowMainContent__contentTabs__ZZNfI";
export var mainContentContainer = "ProviderShowMainContent__mainContentContainer__bBarA";
export var providerName = "ProviderShowMainContent__providerName__idBr0";
export var row = "ProviderShowMainContent__row__swe_t";