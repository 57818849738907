// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../../styleguide/components/Link/A.res.js";
import * as H2 from "../../../../../../../styleguide/components/Heading/H2.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_ProviderBrochure from "../../../../../../../routes/common/Routes_ProviderBrochure.res.js";
import * as ProviderShowContentBrochure from "./components/ProviderShowContentBrochure.res.js";
import * as ProviderShowContentBrochuresScss from "./ProviderShowContentBrochures.scss";

var css = ProviderShowContentBrochuresScss;

function ProviderShowContentBrochures(props) {
  var setUserData = props.setUserData;
  var userLoginStatus = props.userLoginStatus;
  var provider = props.provider;
  var providerBrochures = props.providerBrochures;
  var tmp;
  if (providerBrochures.length !== 0) {
    tmp = Belt_Array.mapWithIndex(providerBrochures, (function (index, brochure) {
            return JsxRuntime.jsx(ProviderShowContentBrochure.make, {
                        providerBrochure: brochure,
                        userLoginStatus: userLoginStatus,
                        setUserData: setUserData
                      }, String(index));
          }));
  } else {
    var tmp$1;
    tmp$1 = props.userRole === "Admin" ? JsxRuntime.jsxs("p", {
            children: [
              "As an admin, you may add brochures by clicking ",
              JsxRuntime.jsxs(A.make, {
                    href: Routes_ProviderBrochure.Dashboard.$$new,
                    className: css.addBrochureLink,
                    children: [
                      "here",
                      "."
                    ]
                  })
            ]
          }) : null;
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("p", {
                  children: provider.name + " has not added any brochures. "
                }),
            tmp$1
          ],
          className: css.noBrochures,
          id: "noBrochures"
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      id: "brochuresTitle",
                      className: css.brochuresTitle,
                      children: "Brochures: " + provider.name
                    }),
                tmp
              ],
              className: css.brochures,
              id: "brochures"
            });
}

var make = ProviderShowContentBrochures;

export {
  css ,
  make ,
}
/* css Not a pure module */
