// extracted by mini-css-extract-plugin
export var certification = "ProviderShowContentCertifications__certification__S8gdg";
export var certificationLabelContainer = "ProviderShowContentCertifications__certificationLabelContainer__LUzIG";
export var certificationTitle = "ProviderShowContentCertifications__certificationTitle__rGzxV";
export var certificationTooltip = "ProviderShowContentCertifications__certificationTooltip__Yeefp";
export var certifications = "ProviderShowContentCertifications__certifications__vXeNU";
export var certificationsRow = "ProviderShowContentCertifications__certificationsRow__gTwJn";
export var certificationsTitle = "ProviderShowContentCertifications__certificationsTitle__MivaM";
export var column = "ProviderShowContentCertifications__column__AZndf";
export var noCertifications = "ProviderShowContentCertifications__noCertifications__ywueh";
export var row = "ProviderShowContentCertifications__row__y9b2i";