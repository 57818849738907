// extracted by mini-css-extract-plugin
export var brochure = "ProviderShowContentBrochure__brochure__vBUzL";
export var column = "ProviderShowContentBrochure__column__CJJ05";
export var downloadContainer = "ProviderShowContentBrochure__downloadContainer__gtqMm";
export var image = "ProviderShowContentBrochure__image__uMyyx";
export var imageContainer = "ProviderShowContentBrochure__imageContainer__z6bBw";
export var paginationContainer = "ProviderShowContentBrochure__paginationContainer__nhcRQ";
export var row = "ProviderShowContentBrochure__row___l_V4";
export var subtitle = "ProviderShowContentBrochure__subtitle__e4uF3";
export var title = "ProviderShowContentBrochure__title__JXsGc";
export var titleContainer = "ProviderShowContentBrochure__titleContainer__aKW8o";